import api from "../configs/api";


export default class LoginService {

    async login(user) {
        return api.post("/auth/login", user);
    }

    async updatePassword(userRequest) {
        return api.put("/auth/update-password", userRequest);
    }

    async validation(hash) {
        return api.get("/auth/validation?hash=" + hash);
    }

}

<template>
  <Toast></Toast>
  <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
      <div class="flex flex-wrap shadow-2">
        <div class="w-full lg:w-6 px-0  py-7 lg:p-7 bg-blue-50">
          <Carousel :value="features" :autoplay-interval="3000"
                    class="mt-7"
                    :numVisible="1" :numScroll="1"
                    :circular="true" :responsive-options="responsiveOptions">
            <template #item="slotProps">
              <div class="text-center mb-8">
                <img :src="'images/blocks/illustration/' + slotProps.data.image" alt="Image" class="mb-6 w-6">
                <div class="mx-auto font-medium text-xl mb-4 text-blue-900">{{slotProps.data.title}}</div>
                <p class="m-0 text-blue-700 line-height-3">{{slotProps.data.text}}</p>
              </div>
            </template>
          </Carousel>
        </div>
        <div class="w-full lg:w-6 p-4 lg:p-7 surface-card">
          <div class="flex align-items-center mb-7">
            <img src="@/assets/logo.png" alt="Image" height="70" class="mr-2">
            <span class="text-2xl font-medium text-900 ">Clinical Mind</span>
          </div>
          <label for="email4" class="block text-900 font-medium mb-2">E-mail</label>
          <InputText id="email4" type="email" class="w-full mb-3 p-3" v-model="user.email"/>

          <label for="password4" class="block text-900 font-medium mb-2">Senha</label>
          <InputText id="password4" v-model="user.password" type="password" class="w-full mb-3 p-3" />

          <div class="flex align-items-center justify-content-between mb-6">
            <div class="flex align-items-center">
              <Checkbox id="rememberme4" :binary="true" v-model="user.rememberMe" class="mr-2"></Checkbox>
              <label for="rememberme4">Lembrar de mim</label>
            </div>
          </div>

          <Button label="Entrar" class="w-full py-3 font-medium" @click="login"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LoginService from "@/service/LoginService";

export default {
  name: "Login",
  loginService: null,
  created() {
    this.loginService = new LoginService();
  },
  mounted() {
  },
  data() {
    return {
      user: {
        email: null,
        password: null,
        rememberMe: false
      },
      features: [
        {
          image: 'live-collaboration.svg',
          title: 'Simplicidade',
          text: 'A organização da sua clínica em um único local.'
        },
        {
          image: 'real-time-collaboration.svg',
          title: 'Comodidade',
          text: 'Gerencie sua clínica aonde estiver. O Clinical Mind é responsivo para todos os dispositivos móveis e desktop.'
        },
        {
          image: 'stats-illustration-6.svg',
          title: 'Crescimento',
          text: 'Gerencie sua clínica aonde estiver. O Clinical Mind é responsivo para todos os dispositivos móveis e desktop.'
        }
      ],
      responsiveOptions: [
        {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3
        },
        {
          breakpoint: '600px',
          numVisible: 2,
          numScroll: 2
        },
        {
          breakpoint: '480px',
          numVisible: 1,
          numScroll: 1
        }
      ]
    }
  },
  methods: {

    async login() {
      const loader = this.$loading.show();
      this.loginService.login(this.user).then((response) => {
        if (response.status === 200) {
          console.info(`USER: ${JSON.stringify(response.data)}`);
          localStorage.setItem("access_token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("clinicalId", response.data.clinicalId);
          localStorage.setItem("clinical", JSON.stringify(response.data.clinical));

          response.data.user.roles.forEach((role) => {
            switch (role.name) {
              case 'ROLE_ATENDIMENTO':
                this.$router.push({name: "agenda-view"});
                break;
              case 'ROLE_ADMIN':
                this.$router.push("admin");
                break;
              case 'ROLE_ESPECIALISTA':
                this.$router.push({name: "agenda-view"});
                break;
            }
          });
        } else if (JSON.parse(response).status === 401) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Clinical-Mind',
            detail: 'Usuário ou senha inválidos!',
            life: 3000
          });
        }
        loader.hide();
      }).catch(() => {
        loader.hide();
        this.$toast.add({
          severity: 'warn',
          summary: 'Clinical Mind',
          detail: 'E-mail ou senha inválidos!',
          life: 3000
        });
      });

    }
  }
}
</script>

<style scoped lang="scss">


</style>
